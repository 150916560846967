var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableview"},[(_vm.loading_page)?_c('div',{staticClass:"cb all mt-5 pt-5"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":""}})],1):_vm._e(),(!_vm.loading_page)?_c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.page_name)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","color":"black"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.page_button_hover == null ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.page_button_setting ? _vm.page_button_setting.show_new : false)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 btn-border",attrs:{"depressed":"","disabled":_vm.btn_new_item},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" "+_vm._s(_vm.page_button_name.name_new)+" ")],1):_vm._e()]}}],null,false,3876405542)},[(_vm.page_button_hover != null)?_c('span',[_vm._v(_vm._s(_vm.page_button_hover.hover_new))]):_vm._e()])],1),_c('v-card-text',[_c('div',{staticStyle:{"width":"50%"}},[_c('p',{staticStyle:{"opacity":"0.7"}},[_vm._v(" "+_vm._s(_vm.page_info)+" ")])])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data_table,"search":_vm.search,"loading":_vm.loading_table,"show-select":false,"footer-props":{'items-per-page-options':[25, 50, 100, -1]}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-border",attrs:{"depressed":"","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"btn-border",attrs:{"depressed":"","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.page_button_hover == null ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.page_button_setting ? _vm.page_button_setting.show_update : false)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.btn_edit},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[(_vm.page_button_hover != null)?_c('span',[_vm._v(_vm._s(_vm.page_button_hover.hover_update))]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.page_button_hover == null ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.page_button_setting ? _vm.page_button_setting.show_delete : false)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","disabled":_vm.btn_delete},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[(_vm.page_button_hover != null)?_c('span',[_vm._v(_vm._s(_vm.page_button_hover.hover_delete))]):_vm._e()])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_c('a',{attrs:{"href":item.image,"target":"_blank","rel":"noopener noreferrer"}},[_c('v-img',{attrs:{"src":item.image,"alt":item.name,"height":"30px","width":"70px"}})],1)])]}}],null,false,3969230979),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading_table),expression:"loading_table"}],attrs:{"slot":"progress","color":"black","absolute":"","indeterminate":""},slot:"progress"})],1)],1)],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"btn-border",attrs:{"depressed":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }