<template>
  <div class="tableview">
    <div class="cb all mt-5 pt-5" v-if="loading_page">
      <v-progress-circular
        :size="50"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <div v-if="!loading_page">
      <v-card>
        <v-card-title>
          {{ page_name }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color="black"
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- button new item -->
          <v-tooltip bottom :disabled="page_button_hover == null ? true : false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="page_button_setting ? page_button_setting.show_new : false"
                v-bind="attrs"
                v-on="on"
                depressed
                class="mb-2 btn-border"
                :disabled="btn_new_item"
                @click="newItem"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-plus-circle-outline
                </v-icon>
                {{page_button_name.name_new}}
              </v-btn>
            </template>
            <span v-if="page_button_hover != null">{{ page_button_hover.hover_new }}</span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <div style="width: 50%;">
            <p style="opacity: 0.7">
              {{ page_info }}
            </p>
          </div>
        </v-card-text>

        <v-data-table
          :headers="headers"
          :items="data_table"
          :search="search"
          :loading="loading_table"
          v-model="selected"
          :show-select="false"
          class="elevation-1"
          :footer-props="{'items-per-page-options':[25, 50, 100, -1]}"
        >
          <v-progress-linear v-show="loading_table" slot="progress" color="black" absolute indeterminate></v-progress-linear>
          <template v-slot:top>
            <v-dialog v-model="dialog_delete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed class="btn-border" text @click="closeDelete">Cancel</v-btn>
                  <v-btn depressed class="btn-border" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom :disabled="page_button_hover == null ? true : false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="page_button_setting ? page_button_setting.show_update : false"
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                  :disabled=btn_edit
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span v-if="page_button_hover != null">{{ page_button_hover.hover_update }}</span>
            </v-tooltip>

            <v-tooltip bottom :disabled="page_button_hover == null ? true : false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="page_button_setting ? page_button_setting.show_delete : false"
                  v-bind="attrs"
                  v-on="on"
                  small
                  :disabled=btn_delete
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span v-if="page_button_hover != null">{{ page_button_hover.hover_delete }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.image="{ item }">
            <div class="p-2">
              <a :href="item.image" target="_blank" rel="noopener noreferrer">
                <v-img :src="item.image" :alt="item.name" height="30px" width="70px"></v-img>
              </a>
            </div>
          </template>
        </v-data-table>
      </v-card>
      
       
    </div>

    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn depressed class="btn-border" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import Mixins from '@/mixins/mixin'

  export default {
    mixins: [Mixins],
    data () {
      return {
        is_super_admin: false,

        Model: null,
        page_button_setting: null,
        page_button_hover: null,
        page_button_name: null,
        page_info: null,
        page_name: null,
        page_path: null,
        page_full_path: null,
        page_with_query: null,
        
        data_table: [],
        headers: [],

        snackbar: false,
        snackbar_text: null,
        
        header_req: {},
        marketplace: null,
        params: {},
        
        shopee_category: [],
        tokopedia_category: [],

        loading_page: true,
        loading_table: true,
        dialog_delete: false,
        options: {},
        search: '',
        selected: [],

        tab: null,
        items: [],

        btn_push: false,
        btn_new_item: false,
        btn_refresh: false,
        btn_edit: false,
        btn_delete: false,

        delete_items: [],
      }
    },
    created : async function () {
      let user = JSON.parse(this.$cookie.get(`data_user`));
      this.is_super_admin = user.is_super_admin
      this.page_path = this.$route.path;
      this.page_with_query = this.$route.query.type;
      this.page_full_path = this.$route.fullPath;
      this.Model = await this.getAuth(this.page_path)
    }, 
    mounted () {
      let promise_menu_info = this.getMenuInfo()
      Promise.all([promise_menu_info]).then(() => {
        this.loading_page = false
        this.getData()
      });
    },
    methods: {
      refresh : async function () {
        this.logicButton(true)
        this.header_req.create = true

        let res = await this.Model.get(this.header_req, this.params);
        this.showMessage(true, res.data.message)

        setTimeout(() => {
          this.$router.go()
        }, 2000)
        
        this.logicButton(false)
      },

      newItem : async function () {
        this.$router.push({ path: `${this.page_path}/create`});
      },

      logicButton (disabled) {
        this.btn_refresh = disabled;
        this.btn_push = disabled;
        this.loading_table = disabled;
        this.btn_delete = disabled;
        this.btn_edit = disabled;
      },

      showMessage (snackbar, msg) {
        this.snackbar = snackbar;
        this.snackbar_text = msg;
      },

      getMenuInfo : async function () {
        let filter = this.page_with_query ? this.page_full_path : this.page_path;
        let get_menu_info = await this.getMenuInfoMixin(filter);
        if (get_menu_info.status == 400) {
          this.showMessage(true, get_menu_info.msg);
        } else if (get_menu_info.status == 200) {
          let gmi = get_menu_info.data;
          this.page_button_setting = gmi.button_setting ? gmi.button_setting : null;
          this.page_button_hover = gmi.button_hover ? gmi.button_hover : null;
          this.page_button_name = gmi.button_name ? gmi.button_name : null;
          this.page_name = gmi.menu_name;
          this.page_info = gmi.menu_info;
        }
      },

      editItem (items) {
        this.$router.push({ path: `${this.page_path}/${items._id}`, param: { id: items._id } });
      },

      deleteItem (items) {
        this.dialog_delete = true
        this.delete_items = items
      },

      deleteItemConfirm: async function() {
        if (this.delete_items) {
          this.loading_table = true
          let res = await this.Model.delete(/* this.header_req, */ this.delete_items._id);
          this.showMessage(true, res.data.message)
          this.closeDelete()
          setTimeout(() => {
            this.loading_table = false
            this.$router.go()
          }, 1000)
        }
      },

      closeDelete () {
        this.delete_items = []
        this.dialog_delete = false
      },

      getData : async function () {
        let datas = [];
        let res = this.page_with_query ? await this.Model.getWithQuery(this.page_with_query) : await this.Model.get();
        if (res.data.status == 200) {
          //GET DATA FROM BACK END
          for (let i = 0; i < res.data.data.length; i++) {
            let temp = res.data.data[i];
            datas.push(temp)
          }
          this.data_table = datas
        } else if (res.data.status == 400 || res.data.status == 201) {
          console.log(`${res.data.message} \n${res.data.err}`)
        }
        this.loading_table = false
        this.headers = res.data.fields
      },

      //RESET ALL DATA 
      reset () {
        this.header_req = {};
        this.params = {};
        this.headers = [];
        this.data_table = [];
      }
    },
  }
</script>

<style scoped>
  .tableview {
    padding: 20px 10px;
  }
 
</style>
